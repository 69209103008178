import React from 'react';
import styles from '../styles/Home.module.css';
import Spacer from '../components/Spacer';

const testimonials = [
    { id: 1, text: "This was an amazing event! - John Doe" },
    { id: 2, text: "Absolutely loved it, can't wait for the next one! - Jane Smith" },
    { id: 3, text: "An unforgettable experience! - Emily Wright" }
];

const Home = () => {
    return (
        <div className={styles.homeContainer}>
            <h1 className={styles.homeHeading}>Sign Up for Your Next Date Today</h1>
            <div className={styles.contentContainer}>
                <div className={styles.testimonials}>
                    <h2>Real Stories</h2>
                    {testimonials.map((testimonial, index) => (
                        <p key={testimonial.id} className={styles.testimonialItem} style={{ animationDelay: `${index * 5}s` }}>
                            {testimonial.text}
                        </p>
                    ))}
                </div>
                <div className={styles.signupForm}>
                    <h2>Register Today</h2>
                    <form>
                        <label>
                            Name:
                            <input type="text" name="name" />
                        </label>
                        <Spacer/>
                        <label>
                            Email:  
                            <input type="email" name="email" />
                        </label>
                        <button type="submit">Sign Up</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Home;
