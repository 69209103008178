import React from 'react';

// eslint-disable-next-line react/prop-types
const Spacer = ({ size = 10 }) => {
    const style = {
        height: size,
        width: '100%',
    };

    return <div style={style} />;
};

export default Spacer;