import React from 'react';
import styles from '../styles/Header.module.css';

function Header() {
    return (
        <header className={styles.header}>
            <h3>Welcome to <a>unsettl.com</a></h3>
        </header>
    );
}

export default Header;
