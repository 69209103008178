import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
// import Events from './pages/Events';
// import SignUp from './pages/SignUp';
// import Dashboard from './pages/Dashboard';

function App() {
  return (
    <Router>
      <Header/>
      <div style={{ paddingTop: '25x', paddingBottom: '25px' }} className="App">
          <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/events" element={<Events />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/dashboard" element={<Dashboard />} /> */}
          </Routes>
      </div>
      <Footer/>
    </Router>
  );
}

export default App;
